/** @format */

import { PatientVitalsReadingType } from './../types/PatientVitals.types';
// Lib
import { DateTime } from 'luxon';

// Types
import { LineChartsDataType } from '../types/LineChart.types';
import _ from 'underscore';

export interface DeCouplePatientVitalsIntoFormValueType {
  value: number | string;
  date: string;
  dateTimestamp: string;
  sourceType: string;
  unit: string;
  severity: string;
  vitalSeverity: string;
}

export const deCouplePatientVitalsIntoFormValue = (
  vitalsType: string,
  vitals?: PatientVitalsReadingType | PatientVitalsReadingType[],
  subType?: string,
  dateFormat = 'yyyy-MM-dd',
): Partial<DeCouplePatientVitalsIntoFormValueType> => {
  // sourceType: 'MANUAL_ENTRY';

  const vital = {};
  if (vitals && Array.isArray(vitals)) {
    const data = vitals.find((ele: any) => ele.type === vitalsType);
    // const data = vitals.find((ele: any) => ele.type === 'HEART_RATE');

    if (data) {
      let value = data.measurements[0]?.value;
      if (vitalsType === 'BLOOD_PRESSURE' && !subType) {
        const sys = data.measurements.find((ele: any) => ele.name === 'SYSTOLIC')?.value;
        // let dia = data.measurements.find((ele) => ele.name === 'DIASTOLIC')?.value;
        value = sys;
      }

      if (subType === 'SYSTOLIC') {
        value = data.measurements.find((ele: any) => ele.name === 'SYSTOLIC')?.value;
      }

      if (subType === 'DIASTOLIC') {
        value = data.measurements.find((ele: any) => ele.name === 'DIASTOLIC')?.value;
      }

      if (subType === 'FEET') {
        value = data.measurements.find((ele: any) => ele.name === 'FEET')?.value;
      }

      if (subType === 'INCHES') {
        value = data.measurements.find((ele: any) => ele.name === 'INCHES')?.value;
      }

      if (vitalsType === 'HEART_RATE') {
        value = data.measurements.find((ele: any) => ele.name === 'HEART_RATE')?.value;
      }

      let date;
      let dateTimestamp;

      if (data.timestamp) {
        date = DateTime.fromJSDate(new Date(data.timestamp)).toISODate()!;
        dateTimestamp = DateTime.fromJSDate(new Date(data.timestamp)).toFormat('yyyy-MM-dd HH:mm:ss');
      }

      return { value, date, sourceType: data.sourceType, dateTimestamp, unit: data?.measurements[0]?.unit, severity: data?.vitalSeverity, vitalSeverity: data?.vitalSeverity };
    }
  } else if (!_.isEmpty(vitals) && vitals?.type === vitalsType) {
    let value = vitals?.measurements[0]?.value;
    if (vitalsType === 'BLOOD_PRESSURE' && !subType) {
      const sys = vitals?.measurements.find((ele: any) => ele.name === 'SYSTOLIC')?.value;
      // let dia = vitals.measurements.find((ele) => ele.name === 'DIASTOLIC')?.value;
      value = sys;
    }

    if (subType === 'SYSTOLIC') {
      value = vitals?.measurements.find((ele: any) => ele.name === 'SYSTOLIC')?.value;
    }

    if (subType === 'DIASTOLIC') {
      value = vitals?.measurements.find((ele: any) => ele.name === 'DIASTOLIC')?.value;
    }

    let date;
    let dateTimestamp;

    if (vitals?.timestamp) {
      date = DateTime.fromJSDate(new Date(vitals.timestamp)).toISO()!;
      dateTimestamp = DateTime.fromJSDate(new Date(vitals.timestamp)).toFormat('yyyy-MM-dd HH:mm:ss');
    }

    return {
      value: customConvertFloatToIntV2(value as string),
      date,
      dateTimestamp,
      sourceType: vitals?.sourceType,
      unit: vitals?.measurements[0]?.unit,
      severity: vitals?.vitalSeverity,
      vitalSeverity: vitals?.vitalSeverity,
    };
  }

  return vital;
};

export function formatPatientVitalsIntoTreadData (readings: Array<any>, filterType: string): LineChartsDataType[] {
  const filteredReading: LineChartsDataType[] = readings?.map((reading: any) => {
    const date = new Date(`${DateTime.fromJSDate(new Date(reading.timestamp)).toFormat('yyyy-MM-dd')} 00:00:00`);

    let value = 0;

    if (reading.measurements.length > 0) {
      if (filterType === 'bp') {
        value = reading.measurements.find((ele: any) => ele.name?.toLowerCase().includes('sys'))?.value;
      } else if (filterType === 'dbp') {
        value = reading.measurements.find((ele: any) => ele.name?.toLowerCase().includes('dia'))?.value;
      } else {
        value = reading.measurements[0].value;
      }
    }

    const data: LineChartsDataType = {
      date,
      value: Number(value),
    };
    return data;
  });
  return filteredReading;
}

export function formatPatientMedicalEventsForLineChart (medicalEvents: any) {
  // Filtering Medical Events
  const filteredMedicalEvents = medicalEvents?.reduce((acc: any[], ele: any) => {
    if (ele.type === 'PATIENT_SYMPTOMS') {
      acc.push({
        date: new Date(ele.timestamp),
        patientSymptoms: ele.payload?.symptoms && ele.payload?.symptoms?.symptoms ? ele.payload?.symptoms?.symptoms : [],
      });
    }

    // Taking Symptoms from Symptoms api, Instead of taking from Medical-Events api
    if (Object.hasOwn(ele, 'symptoms')) {
      const noSymptoms: { category: string; description: string; shortcode: string }[] = [];
      const patientSymptoms = ele.symptoms?.reduce((acc: any[], symptom: { category: string; description: string; shortcode: string }) => {
        if (symptom.category === 'NO_SYMPTOMS') {
          noSymptoms.push({ ...symptom });
        }

        if (symptom.category !== 'NO_SYMPTOMS') {
          acc.push(symptom);
        }

        return acc;
      }, []);

      if (_.isEmpty(patientSymptoms) && !_.isEmpty(noSymptoms)) {
        acc.push({
          date: new Date(ele.timestamp),
          patientNoSymptoms: noSymptoms,
        });
      }

      if (!_.isEmpty(patientSymptoms)) {
        acc.push({
          date: new Date(ele.timestamp),
          patientSymptoms,
        });
      }

      // acc.push({
      //   date: new Date(ele.timestamp),
      //   patientSymptoms: ele.symptoms ? ele.symptoms : [],
      // });
    }

    if (ele.type === 'MEDICATION_CHANGE') {
      let prescription = [ele.payload?.prescription];

      if (ele.payload?.prescription?.prescriptionMedications) {
        prescription = ele.payload?.prescription?.prescriptionMedications;
      }

      const notes = Array.isArray(ele.payload?.prescription?.notes) ? ele.payload?.prescription?.notes : [ele.payload?.prescription?.notes];

      acc.push({
        recordId: ele.recordId,
        date: new Date(ele.timestamp),
        medicationChange: [...prescription, ...notes],
      });
    }

    if (ele.type === 'PATIENT_MEDICATION_ACCEPTANCE') {
      acc.push({
        date: new Date(ele.timestamp),
        medicationAcceptance: ele.payload.prescription?.medications,
      });
    }

    // const objectKeys = Object.keys(ele.payload);
    // if (ele.type === 'LAB_REPORTS' && objectKeys.includes('patientLabReports') && ele.payload?.patientLabReports) {
    //   acc.push({
    //     date: new Date(ele.timestamp),
    //     patientTestReports: ele.payload?.patientLabReports?.reports,
    //   });
    // }

    return acc;
  }, []);

  return filteredMedicalEvents;
}

export function formatPatientLabReportsForLineChart (labReports: any) {
  const filteredLabReports = labReports?.reduce((acc: any, ele: any) => {
    const objectKeys = Object.keys(ele.payload);
    if (ele.type === 'LAB_REPORTS') {
      if (objectKeys.includes('patientLabReports') && ele.payload?.patientLabReports?.reports?.length) {
        acc.push({
          date: new Date(ele.timestamp),
          patientTestReports: ele.payload?.patientLabReports?.reports,
        });
      }

      // if (objectKeys.includes('vitals') && ele.payload?.vitals?.length) {
      //   acc.push({
      //     recordId: ele.recordId,
      //     date: new Date(ele.timestamp),
      //     labReports: ele.payload?.vitals,
      //   });
      // }
    }

    return acc;
  }, []);

  return filteredLabReports;
}

export function formatPatientLabReports (potassiumLabReports: any, scrLabReports: any, egfrLabReports: any) {
  // Filtering Lab Reports
  const arr = [...potassiumLabReports, ...scrLabReports, ...egfrLabReports];
  const data = _.groupBy(arr, (ele: any) => {
    return ele.timestamp;
  });

  const filteredLabReportsArray = [];
  for (const x in data) {
    filteredLabReportsArray.push({
      date: new Date(x),
      labReports: data[x].map((ele: any) => ele.measurements).flat(),
    });
  }
  // Filtering Lab Reports

  return filteredLabReportsArray;
}

export const compareApiData = (a: { date: string | number | Date; recordId: number }, b: { date: string | number | Date; recordId: number }) => {
  // Check if both date exist
  if (!(b?.date && a?.date)) return 0;

  // Convert dates to same format for comparison
  const dateA = DateTime.fromJSDate(new Date(a.date)).toFormat('yyyy-MM-dd 00:00:00');
  const dateB = DateTime.fromJSDate(new Date(b.date)).toFormat('yyyy-MM-dd 00:00:00');

  // If dates are equal sort by recordId else sort by date wise
  if (new Date(dateA).getTime() === new Date(dateB).getTime()) return b.recordId - a.recordId;
  else return new Date(b.date).getTime() - new Date(a.date).getTime();
};

export const getAllUserSelectedDateAndValues = (startDate: string, endDate: string, apiData: any, vitalType: string) => {
  // Counting Days between startDate && endDate
  const { days } = DateTime.fromISO(startDate).diff(DateTime.fromISO(endDate), ['days']).toObject();

  if (['bp', 'hr', 'weight', 'spo2', 'step_count'].includes(vitalType)) {
    const data: any = [];

    for (let i = 0; i <= Math.abs(days!); i++) {
      const currentDate = DateTime.fromISO(startDate).plus({ days: i });
      const isoDate = currentDate.toISODate();

      const values = apiData?.filter((ele: any) => DateTime.fromISO(ele.date).toISODate() === isoDate);

      const defaultData = {
        date: new Date(currentDate.toISO() as string),
        value: undefined,
        sourceType: undefined,
        severity: undefined,
        unit: undefined,
      };

      if (values && values.length > 0) {
        values.forEach((value: any) => {
          data.push({
            // date: new Date(DateTime.fromISO(date as any).toISO() as string),
            date: new Date(currentDate.toISO() as string),
            value: value?.value ? value?.value : undefined,
            sourceType: value?.sourceType ? value?.sourceType : undefined,
            severity: value?.severity ? value?.severity : undefined,
            unit: value?.unit ? value?.unit : undefined,
          });
        });
      } else {
        data.push(defaultData);
      }
    }

    return data;
  }

  if (vitalType === 'medical_events') {
    // Sorting the api data in descending order for getting the latest MEDICAL_EVENTS
    // Sort By Date Wise && Sort By recodeId
    apiData = apiData.sort(compareApiData);

    // Adding All Dates
    const data: any = [];
    try {
      for (let i = 0; i <= Math.abs(days!); i++) {
        const date = DateTime.fromJSDate(new Date(startDate)).plus({ days: i }).toJSDate();
        const values = apiData?.filter((ele: any) => DateTime.fromJSDate(new Date(ele?.date)).toFormat('yyyy-MM-dd') === DateTime.fromJSDate(new Date(date)).toFormat('yyyy-MM-dd'));

        let allPatientsSymptoms: any = [];
        if (values) {
          values?.forEach((value: any, i: number) => {
            const valueKeys = Object.keys(value);

            if (valueKeys.includes('medicationChange')) {
              const medicationChangeData = {
                date: new Date(DateTime.fromJSDate(date).toFormat('yyyy-MM-dd 00:00:00')),
                medicationChange: value?.medicationChange ? value?.medicationChange : [],
              };

              data.push(medicationChangeData);
            } else if (valueKeys.includes('patientSymptoms')) {
              if (_.isEmpty(allPatientsSymptoms)) {
                values?.forEach((ele: { patientSymptoms: Array<any> }) => ele?.patientSymptoms && (allPatientsSymptoms = allPatientsSymptoms.concat(ele?.patientSymptoms)));

                const patientSymptomsData = {
                  date: new Date(DateTime.fromJSDate(date).toFormat('yyyy-MM-dd 00:00:00')),
                  patientSymptoms: allPatientsSymptoms,
                };

                data.push(patientSymptomsData);
              }
            } else if (valueKeys.includes('patientNoSymptoms')) {
              const patientNoSymptomsData = {
                date: new Date(DateTime.fromJSDate(date).toFormat('yyyy-MM-dd 00:00:00')),
                patientNoSymptoms: value?.patientNoSymptoms ? value?.patientNoSymptoms : [],
              };

              data.push(patientNoSymptomsData);
            } else if (valueKeys.includes('medicationAcceptance')) {
              const medicationAcceptanceData = {
                date: new Date(DateTime.fromJSDate(date).toFormat('yyyy-MM-dd 00:00:00')),
                medicationAcceptance: value?.medicationAcceptance ? value?.medicationAcceptance : [],
              };

              data.push(medicationAcceptanceData);
            } else if (valueKeys.includes('patientTestReports')) {
              const patientTestReportsData = {
                date: new Date(DateTime.fromJSDate(date).toFormat('yyyy-MM-dd 00:00:00')),
                patientTestReports: value?.patientTestReports ? value?.patientTestReports : [],
              };

              data.push(patientTestReportsData);
            }
          });
        }
      }
    } catch (err) {
      console.error('err', err);
    }

    return data;
  }

  if (vitalType === 'labReports') {
    // Sorting the api data in descending order for getting the latest LAB_REPORTS
    // Sort By Date Wise && Sort By recodeId
    apiData = apiData.sort(compareApiData);

    // Adding All Dates
    const data: any = [];
    for (let i = 0; i <= Math.abs(days!); i++) {
      const date = DateTime.fromJSDate(new Date(startDate)).plus({ days: i }).toJSDate();

      const labReportValue: any = apiData?.find((ele: any) => {
        const isSameDate = DateTime.fromJSDate(new Date(ele.date)).startOf('day').toFormat('yyyy-MM-dd') === DateTime.fromJSDate(new Date(date)).startOf('day').toFormat('yyyy-MM-dd');
        return isSameDate && !_.isEmpty(ele.labReports);
      });

      const labReports = labReportValue && labReportValue?.labReports ? labReportValue.labReports.flatMap((ele: { measurements: any[] }) => ele.measurements.flat()) : [];

      const patientTestReportValue: any = apiData?.find((ele: any) => {
        const isSameDate = DateTime.fromJSDate(new Date(ele.date)).startOf('day').toFormat('yyyy-MM-dd') === DateTime.fromJSDate(new Date(date)).startOf('day').toFormat('yyyy-MM-dd');
        return isSameDate && !_.isEmpty(ele.patientTestReports);
      });

      const patientTestReports = patientTestReportValue && patientTestReportValue?.patientTestReports ? patientTestReportValue.patientTestReports : [];

      if (!_.isEmpty(labReports)) {
        const labReportsData = {
          date: new Date(DateTime.fromJSDate(date).toFormat('yyyy-MM-dd 00:00:00')),
          labReports,
        };
        data.push(labReportsData);
      }

      if (!_.isEmpty(patientTestReports)) {
        const patientTestReportsData = {
          date: new Date(DateTime.fromJSDate(date).toFormat('yyyy-MM-dd 00:00:00')),
          patientTestReports,
        };
        data.push(patientTestReportsData);
      }
    }

    return data;
  }
};

export const decoupleMedicalAdherence = (data: any) => {
  if (data === undefined) {
    data = [];
  }
  if (data?.length > 0) {
    // const latestMedicalAdherence = data[data?.length - 1];

    if (data) {
      return data;
    } else {
      return [];
    }
  } else {
    return data;
  }
};

export const customConvertFloatToInt = (val: string) => {
  // Converting string to number
  let value = Number(val);

  /* Converting Float to Integer */
  const numberIsFloat = value % 1 !== 0;

  const splitedValue = value.toString().split('.');
  if (Number(splitedValue[1]) === 0) value = parseInt(`${value}`);
  else if (Number(splitedValue[1]) > 0 && numberIsFloat) value = Number(value.toFixed(1));
  /* Converting Float to Integers */

  return value;
};

export const customConvertFloatToIntV2 = (val: string) => {
  // Converting string to number
  const value = Number(val);

  /* Converting Float to Integer */
  const numberIsFloat = value % 1 !== 0;

  if (numberIsFloat) {
    return value.toFixed(2);
  }
  /* Converting Float to Integers */

  return value;
};
